<template>
	<!--	Empty template for page redirect-->
	<div></div>
</template>

<script setup>
	const runtimeConfig = useRuntimeConfig()
	const firstPage = runtimeConfig.public.PAGES[0]

	//Routing away from this page when this component is mounted allows Vite to build this page. Otherwise, the index.html file is not generated.
	onMounted(() => {
		navigateTo({
			path: firstPage.urlPath,
		})
	})
</script>
